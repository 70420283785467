import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import logo from "../assets/logo.svg";
import { useTranslation } from "react-i18next";
import "./NavMainFooter.css";

export default function NavMainFooter({ main }) {
  const { t, i18n } = useTranslation();
  const [activeKey, setActiveKey] = useState("home");

  const onSelect = (eventKey, event) => {
    if (eventKey === "lang-en") {
      i18n.changeLanguage("en");
    } else if (eventKey === "lang-es") {
      i18n.changeLanguage("es");
    } else {
      setActiveKey(eventKey)
    };
  };

  return (
    <>
      <Navbar
        id="main-navbar"
        collapseOnSelect
        expand="md"
        fixed="top"
        variant="dark"
        bg="light"
        className="navbar-main"
      >
        <Container>
          <Navbar.Brand href="/home">
            <Image
              alt="Kaeus"
              src={logo}
              className="navbar-logo align-self-center"
              href="#"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end p-2 px-3"
            bg="light" variant="light"
            style={{ backgroundColor: "#111111" }}
          >
            <Nav onSelect={onSelect} activeKey={activeKey} className="text-nowrap">
              <Nav.Link eventKey="home" active={false} to="/home#home" href="#home">
                {t("Home")}
              </Nav.Link>
              <Nav.Link eventKey="aboutus" active={false} to="/home#aboutus" href="#aboutus">
                {t("About Us")}
              </Nav.Link>
              <Nav.Link eventKey="services" active={false} to="/home#services" href="#services">
                {t("Services")}
              </Nav.Link>
              <Nav.Link eventKey="contactus" active={false} to="/home#contactus" href="#contactus">
                {t("Contact Us")}
              </Nav.Link>
              <Container className="d-flex justify-content-end align-items-center ps-2 fs-6">
                <Nav.Link eventKey="lang-en" active={i18n.language==='en'} className="pe-0 fw-light">en</Nav.Link>
                <span className="nav-link px-1">|</span>
                <Nav.Link eventKey="lang-es" active={i18n.language==='es'} className="ps-0 fw-light">es</Nav.Link>
              </Container>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container fluid="true" className="navmainfooter-content mx-0 mb-2">
        {main}
      </Container>

      <Navbar className="align-center navbar-footer mt-5">
        <Container>
          <Row className="align-items-center">
          <span className="fs-6 text-muted">
            2024 &copy; Kaeus Inc, All rights reserved
          </span>
          </Row>
        </Container>
      </Navbar></>
  );
}
